<script setup>
import {computed, onMounted, reactive, ref, watch} from "vue";
import {createApp, defineComponent} from 'vue/dist/vue.esm-bundler';
import {useToast} from "primevue/usetoast";
import {storeToRefs} from "pinia";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {MemberStore} from "@/uhas/store/member";
import {CalculatorStore} from "@/uhas/store/calculator";

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Avatar from 'primevue/avatar';

import AddressSelector from '@/uhas/components/AddressSelector';

const toast = useToast();
// const confirm = useConfirm();

const memberStore = MemberStore();
const calculatorStore = CalculatorStore();
const {liveActivity} = storeToRefs(memberStore);
const {configs} = storeToRefs(calculatorStore);

/**
 * Data
 */
const loading = ref(false);
const blockOverlay = ref(false);
const leaderboardsRows = ref(10);

const confirmExnessLinkAccountDialog = ref(false);
const exnessClientIdState = reactive({exnessClientID: null});
const exnessClientIdValidation = useVuelidate({
	exnessClientID: { required }
}, exnessClientIdState);
const exnessClientIdSubmitted = ref(false);
const tempExness = ref(null);

const confirmXmLinkAccountDialog = ref(false);
const xmClientIdState = reactive({login_id: null});
const xmClientIdValidation = useVuelidate({
	login_id: { required }
}, xmClientIdState);
const xmClientIdSubmitted = ref(false);
const tempXm = ref(null);

const confirmHfmLinkAccountDialog = ref(false);
const hfmClientIdState = reactive({client_id: null});
const hfmClientIdValidation = useVuelidate({
	client_id: { required }
}, hfmClientIdState);
const hfmClientIdSubmitted = ref(false);
const tempHfm = ref(null);

const confirmFxgtLinkAccountDialog = ref(false);
const fxgtClientIdState = reactive({login_id: null});
const fxgtClientIdValidation = useVuelidate({
	login_id: { required }
}, fxgtClientIdState);
const fxgtClientIdSubmitted = ref(false);
const tempFxgt = ref(null);

const confirmAxiLinkAccountDialog = ref(false);
const axiClientIdState = reactive({login: null});
const axiClientIdValidation = useVuelidate({
	login: { required }
}, axiClientIdState);
const axiClientIdSubmitted = ref(false);
const tempAxi = ref(null);

const exnessConnect = `
<div class="mt-6 field w-max max-w-full">
	<div class="p-inputgroup">
		<InputText
			v-model="exnessClientIdValidation.exnessClientID.$model"
			placeholder="เลขบัญชีจริง exness"
			:class="{'p-error': exnessClientIdValidation.exnessClientID.$invalid && exnessClientIdSubmitted}"
		/>
		<Button
			:loading="loading"
			:disabled="loading"
			icon="pi pi-link"
			class="p-button-outlined"
			label="ผูกบัญชี exness"
			@click="requestLinkWithExness"
		/>
	</div>
	<small v-if="(exnessClientIdValidation.exnessClientID.$invalid && exnessClientIdSubmitted) || exnessClientIdValidation.exnessClientID.$pending.$response" class="p-error">
		{{exnessClientIdValidation.exnessClientID.required.$message.replace("Value", "เลขบัญชีจริง exness")}}
	</small>
</div>
`;

const xmConnect = `
<div class="mt-6 field w-max max-w-full">
	<div class="p-inputgroup">
		<InputText
			v-model="xmClientIdValidation.login_id.$model"
			placeholder="เลขบัญชีจริง xm"
			:class="{'p-error': xmClientIdValidation.login_id.$invalid && xmClientIdSubmitted}"
		/>
		<Button
			:loading="loading"
			:disabled="loading"
			icon="pi pi-link"
			class="p-button-outlined"
			label="ผูกบัญชี xm"
			@click="requestLinkWithXm"
		/>
	</div>
	<small v-if="(xmClientIdValidation.login_id.$invalid && xmClientIdSubmitted) || xmClientIdValidation.login_id.$pending.$response" class="p-error">
		{{xmClientIdValidation.login_id.required.$message.replace("Value", "เลขบัญชีจริง xm")}}
	</small>
</div>
`;

const hfmConnect = `
<div class="mt-6 field w-max max-w-full">
	<div class="p-inputgroup">
		<InputText
			v-model="hfmClientIdValidation.client_id.$model"
			placeholder="เลขบัญชีจริง hfm"
			:class="{'p-error': hfmClientIdValidation.client_id.$invalid && hfmClientIdSubmitted}"
		/>
		<Button
			:loading="loading"
			:disabled="loading"
			icon="pi pi-link"
			class="p-button-outlined"
			label="ผูกบัญชี hfm"
			@click="requestLinkWithHfm"
		/>
	</div>
	<small v-if="(hfmClientIdValidation.client_id.$invalid && hfmClientIdSubmitted) || hfmClientIdValidation.client_id.$pending.$response" class="p-error">
		{{hfmClientIdValidation.client_id.required.$message.replace("Value", "เลขบัญชีจริง hfm")}}
	</small>
</div>
`;

const fxgtConnect = `
<div class="mt-6 field w-max max-w-full">
	<div class="p-inputgroup">
		<InputText
			v-model="fxgtClientIdValidation.login_id.$model"
			placeholder="เลขบัญชีจริง fxgt"
			:class="{'p-error': fxgtClientIdValidation.login_id.$invalid && fxgtClientIdSubmitted}"
		/>
		<Button
			:loading="loading"
			:disabled="loading"
			icon="pi pi-link"
			class="p-button-outlined"
			label="ผูกบัญชี fxgt"
			@click="requestLinkWithFxgt"
		/>
	</div>
	<small v-if="(fxgtClientIdValidation.login_id.$invalid && fxgtClientIdSubmitted) || fxgtClientIdValidation.login_id.$pending.$response" class="p-error">
		{{fxgtClientIdValidation.login_id.required.$message.replace("Value", "เลขบัญชีจริง fxgt")}}
	</small>
</div>
`;

const axiConnect = `
<div class="mt-6 field w-max max-w-full">
	<div class="p-inputgroup">
		<InputText
			v-model="axiClientIdValidation.login.$model"
			placeholder="เลขบัญชีจริง axi"
			:class="{'p-error': axiClientIdValidation.login.$invalid && axiClientIdSubmitted}"
		/>
		<Button
			:loading="loading"
			:disabled="loading"
			icon="pi pi-link"
			class="p-button-outlined"
			label="ผูกบัญชี axi"
			@click="requestLinkWithAxi"
		/>
	</div>
	<small v-if="(axiClientIdValidation.login.$invalid && axiClientIdSubmitted) || axiClientIdValidation.login.$pending.$response" class="p-error">
		{{axiClientIdValidation.login.required.$message.replace("Value", "เลขบัญชีจริง axi")}}
	</small>
</div>
`;

const leaderboard = `
<DataTable
	v-if="configs?.uhasActivityActive?.leaderboard_show === true && liveActivity && liveActivity.leaderboards"
	class="p-datatable-sm mt-6"
	responsiveLayout="scroll"
	:value="liveActivity.leaderboards?.filter(item => item.score && item.score > 0).slice(0, leaderboardsRows)"
	rowHover
>
	<template #header>
		<h2>Leaderboard</h2>
		<small class="text-primary">Updated At : {{ liveActivity.leaderboards_updated_at }}</small>
	</template>

	<Column header="Place">
		<template #body="{index}">
			<b>{{ index + 1 }}</b>
		</template>
	</Column>
	<Column header="User">
		<template #body="{data}">
			<Avatar
				:image="data.avatar"
				class="mt-1"
				shape="circle"
				size="large"
				@error="data.avatar = 'https://cdn.uhas.com/avatar/no-img.png'"
			/>
		</template>
	</Column>
	<Column field="uhas_uid" header="ID"></Column>
	<Column field="abs_gain" header="Abs. Gain">
		<template #body="{data}">
			<span :class="[data.abs_gain >= 0 ? 'text-green-500' : 'text-pink-500']">{{ data.abs_gain }}%</span>
		</template>
	</Column>
	<Column field="drawdown" header="DD"></Column>
	<Column field="score" header="Score">
		<template #body="{data}">
			<b>{{ data.score }}</b>
		</template>
	</Column>

	<template
		#footer
		v-if="leaderboardsRows !== 100"
	>
		<div class="text-center">
			<Button
				class="mx-auto p-button-text"
				@click="leaderboardsRows = 100"
			>
				<i class="pi pi-angle-double-down"></i>
				<span class="mx-3">Show Top 100</span>
				<i class="pi pi-angle-double-down"></i>
			</Button>
		</div>
	</template>
</DataTable>
`

/**
 * Computed
 */
// const exness = computed(() => memberStore.user?.exness || tempExness.value || null);
const exnessConnectedHTML = computed(() => (configs?.value?.frontendDashboardExnessConnected?.contents || '').replace(/\[show_exness_connected\]/g, memberStore.user?.exness?.from_exness_id || '-'));
const xmConnectedHTML = computed(() => (configs?.value?.frontendDashboardXmConnected?.contents || '').replace(/\[show_xm_connected\]/g, memberStore.user?.xm?.login_id || '-'));
const hfmConnectedHTML = computed(() => (configs?.value?.frontendDashboardHfmConnected?.contents || '').replace(/\[show_hfm_connected\]/g, memberStore.user?.hfm?.client_id || memberStore.user?.hfm?.from_hfm_id || '-'));
const fxgtConnectedHTML = computed(() => (configs?.value?.frontendDashboardFxgtConnected?.contents || '').replace(/\[show_fxgt_connected\]/g, memberStore.user?.fxgt?.login_id || '-'));
const axiConnectedHTML = computed(() => (configs?.value?.frontendDashboardAxiConnected?.contents || '').replace(/\[show_axi_connected\]/g, memberStore.user?.axi?.login || '-'));

/**
 * Watch
 */
watch(configs, val => {
	if (val.uhasActivityActive?.leaderboard_show === true && val.uhasActivityActive?.activity_id) {
		memberStore.snapActivity(val.uhasActivityActive?.activity_id);
	} else {
		memberStore.stopSnapActivity();
	}
}, {deep: true});

/**
 * Mounted
 */
onMounted(() => {
	calculatorStore.getConfigs()
		.then(() => {
			if (configs?.value?.uhasActivityActive?.leaderboard_show === true && configs?.value?.uhasActivityActive?.activity_id) {
				memberStore.snapActivity(configs?.value?.uhasActivityActive?.activity_id);

				if (!memberStore.user?.exness) {
					createApp(
						defineComponent({
							components: {
								Button,
								InputText
							},
							template: (configs?.value?.frontendDashboardExnessNotConnected?.contents || '').replace(/\[show_exness_connect\]/g, exnessConnect),
							data:() => ({
								loading,
								exnessClientIdValidation,
								exnessClientIdSubmitted
							}),
							methods: {requestLinkWithExness}
						})
					).mount("#frontendDashboardExnessNotConnected")
				}

				if (!memberStore.user?.xm) {
					/* eslint-disable vue/no-unused-components */
					createApp(
						defineComponent({
							components: {
								Button,
								InputText
							},
							template: (configs?.value?.frontendDashboardXmNotConnected?.contents || '').replace(/\[show_xm_connect\]/g, xmConnect),
							data:() => ({
								loading,
								xmClientIdValidation,
								xmClientIdSubmitted
							}),
							methods: {requestLinkWithXm}
						})
					).mount("#frontendDashboardXmNotConnected")
				}

				if (!memberStore.user?.hfm) {
					/* eslint-disable vue/no-unused-components */
					createApp(
						defineComponent({
							components: {
								Button,
								InputText
							},
							template: (configs?.value?.frontendDashboardHfmNotConnected?.contents || '').replace(/\[show_hfm_connect\]/g, hfmConnect),
							data:() => ({
								loading,
								hfmClientIdValidation,
								hfmClientIdSubmitted
							}),
							methods: {requestLinkWithHfm}
						})
					).mount("#frontendDashboardHfmNotConnected")
				}

				if (!memberStore.user?.fxgt) {
					/* eslint-disable vue/no-unused-components */
					createApp(
						defineComponent({
							components: {
								Button,
								InputText
							},
							template: (configs?.value?.frontendDashboardFxgtNotConnected?.contents || '').replace(/\[show_fxgt_connect\]/g, fxgtConnect),
							data:() => ({
								loading,
								fxgtClientIdValidation,
								fxgtClientIdSubmitted
							}),
							methods: {requestLinkWithFxgt}
						})
					).mount("#frontendDashboardFxgtNotConnected")
				}

				if (!memberStore.user?.axi) {
					/* eslint-disable vue/no-unused-components */
					createApp(
						defineComponent({
							components: {
								Button,
								InputText
							},
							template: (configs?.value?.frontendDashboardAxiNotConnected?.contents || '').replace(/\[show_axi_connect\]/g, axiConnect),
							data:() => ({
								loading,
								axiClientIdValidation,
								axiClientIdSubmitted
							}),
							methods: {requestLinkWithAxi}
						})
					).mount("#frontendDashboardAxiNotConnected")
				}

				if (configs?.value?.frontendDashboardFooter?.contents) {
					/* eslint-disable vue/no-unused-components */
					createApp(
						defineComponent({
							components: {
								Avatar,
								Button,
								DataTable,
								Column
							},
							template: (configs?.value?.frontendDashboardFooter?.contents || '').replace(/\[leaderboard\]/g, leaderboard),
							data:() => ({
								configs,
								liveActivity,
								leaderboardsRows
							}),
							methods: {requestLinkWithXm},

						})
					).mount("#frontendDashboardFooter")
				}
			}
		});
	// memberStore.getMainActivity()
	// 	.then(() => {
	// 		if (configs?.value?.uhasActivityActive?.leaderboard_show === true) {
	// 			memberStore.snapActivity(activity?.value?.id);
	// 		}
	// 	});
});

/**
 * Actions
 */
const requestLinkWithExness = async () => {
	exnessClientIdSubmitted.value = true;
	if (exnessClientIdValidation.value.$invalid) {
		return
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.getExnessAccount({...exnessClientIdState})
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Exness's account has been linked with another member." : "Cannot get Exness's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		return;
	}

	tempExness.value = result.exness;
	confirmExnessLinkAccountDialog.value = true;
}
const confirmExnessLinkAccount = async () => {
	if (!tempExness.value) {
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: "Please specify the Client ID before linking the account.",
			life: 10000
		});

		return;
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.confirmLinkExnessAccount()
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		const msg = result.error.message || `Cannot link Exness's account. ${result.error.code || ""}`;
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: msg,
			life: 10000
		});
	} else {
		memberStore.user.exness = result.exness;

		toast.add({
			severity:"success",
			summary: "Success Message",
			detail: "Your Exness's account has been linked.",
			life: 10000
		});

		confirmExnessLinkAccountDialog.value = false;
	}
}

const requestLinkWithXm = async () => {
	xmClientIdSubmitted.value = true;
	if (xmClientIdValidation.value.$invalid) {
		return
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.getXmAccount({...xmClientIdState})
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Xm's account has been linked with another member." : "Cannot get Xm's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		return;
	}

	tempXm.value = result.xm;
	confirmXmLinkAccountDialog.value = true;
}
const confirmXmLinkAccount = async () => {
	if (!tempXm.value) {
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: "Please specify the Login ID before linking the account.",
			life: 10000
		});

		return;
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.confirmLinkXmAccount()
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		const msg = result.error.message || `Cannot link Xm's account. ${result.error.code || ""}`;
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: msg,
			life: 10000
		});
	} else {
		memberStore.user.xm = result.xm;

		toast.add({
			severity:"success",
			summary: "Success Message",
			detail: "Your Xm's account has been linked.",
			life: 10000
		});

		confirmXmLinkAccountDialog.value = false;
	}
}

const requestLinkWithHfm = async () => {
	hfmClientIdSubmitted.value = true;
	if (hfmClientIdValidation.value.$invalid) {
		return
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.getHfmAccount({...hfmClientIdState})
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Hfm's account has been linked with another member." : "Cannot get Hfm's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		return;
	}

	tempHfm.value = result.hfm;
	confirmHfmLinkAccountDialog.value = true;
}
const confirmHfmLinkAccount = async () => {
	if (!tempHfm.value) {
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: "Please specify the Client ID before linking the account.",
			life: 10000
		});

		return;
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.confirmLinkHfmAccount()
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		const msg = result.error.message || `Cannot link Hfm's account. ${result.error.code || ""}`;
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: msg,
			life: 10000
		});
	} else {
		memberStore.user.hfm = result.hfm;

		toast.add({
			severity:"success",
			summary: "Success Message",
			detail: "Your Hfm's account has been linked.",
			life: 10000
		});

		confirmHfmLinkAccountDialog.value = false;
	}
}

const requestLinkWithFxgt = async () => {
	fxgtClientIdSubmitted.value = true;
	if (fxgtClientIdValidation.value.$invalid) {
		return
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.getFxgtAccount({...fxgtClientIdState})
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Fxgt's account has been linked with another member." : "Cannot get Fxgt's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		return;
	}

	tempFxgt.value = result.fxgt;
	confirmFxgtLinkAccountDialog.value = true;
}
const confirmFxgtLinkAccount = async () => {
	if (!tempFxgt.value) {
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: "Please specify the Login ID before linking the account.",
			life: 10000
		});

		return;
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.confirmLinkFxgtAccount()
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		const msg = result.error.message || `Cannot link Fxgt's account. ${result.error.code || ""}`;
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: msg,
			life: 10000
		});
	} else {
		memberStore.user.fxgt = result.fxgt;

		toast.add({
			severity:"success",
			summary: "Success Message",
			detail: "Your Fxgt's account has been linked.",
			life: 10000
		});

		confirmFxgtLinkAccountDialog.value = false;
	}
}

const requestLinkWithAxi = async () => {
	axiClientIdSubmitted.value = true;
	if (axiClientIdValidation.value.$invalid) {
		return
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.getAxiAccount({...axiClientIdState})
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Axi's account has been linked with another member." : "Cannot get Axi's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		return;
	}

	tempAxi.value = result.axi;
	confirmAxiLinkAccountDialog.value = true;
}
const confirmAxiLinkAccount = async () => {
	if (!tempAxi.value) {
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: "Please specify the Login ID before linking the account.",
			life: 10000
		});

		return;
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.confirmLinkAxiAccount()
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		const msg = result.error.message || `Cannot link Axi's account. ${result.error.code || ""}`;
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: msg,
			life: 10000
		});
	} else {
		memberStore.user.axi = result.axi;

		toast.add({
			severity:"success",
			summary: "Success Message",
			detail: "Your Axi's account has been linked.",
			life: 10000
		});

		confirmAxiLinkAccountDialog.value = false;
	}
}
</script>

<style lang="scss">
.block-overlay {
	box-shadow: unset;

	.p-dialog-content {
		background: transparent;
    overflow: hidden;

		circle {
			fill: none;
		}
	}
}
.p-datatable-sm {
	max-width: 600px;
	.p-datatable-tbody > tr > td {
		padding: 0 0.4rem;
	}
}
</style>
<style lang="scss" scoped>
::v-deep img {
	max-width: 100%;
}
:v-deep(.block-overlay) {
	box-shadow: unset;
	.p-dialog-content {
		background: transparent;
    overflow: hidden;
	}
}

.avatar-badge {
	width: 24px;
	height: 24px;
	border: 2px solid white;
	position: absolute;
	bottom: 2%;
	right: 2%;
	border-radius: 100%;
}

::v-deep .p-card-body {
	padding: 2rem;
}
// .p-datatable-sm::v-deep {
// 	max-width: 600px;
// 	.p-datatable-tbody > tr > td {
// 		padding: 0 0.4rem;
// 	}
// }
</style>

<template>
	<AddressSelector />
	<Toast />
	<Toast group="html">
		<template #message="slotProps">
				<div class="flex flex-column">
						<div>
								<!-- <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i> -->
								<h4>{{slotProps.message.summary}}</h4>
								<p v-if="slotProps.message.code === 902">
									คุณไม่สามารถเชื่อมต่อได้เนื่องจากคุณได้มีการย้ายโบรกเกอร์แล้ว
								</p>
								<p v-else>
									ไม่พบเลขบัญชี ในระบบของเรา กรุณาติดต่อแอดมินในไลน์
									<a href='https://lin.ee/1eoK6Hd' target='_blank' class="text-900 text-xl font-bold">@uhasthailand</a> หรือ
									<a href='https://lin.ee/1eoK6Hd' target='_blank' class="text-900 text-xl font-bold">คลิก</a>
								</p>
						</div>
				</div>
		</template>
	</Toast>

	<ConfirmPopup />
	<Dialog
		v-model:visible="blockOverlay"
		class="block-overlay"
		appendTo="self"
		:closeOnEscape="false"
		:showHeader="false"
		modal
	>
		<ProgressSpinner
			style="width:5vw;height:5vw"
			strokeWidth="8"
			fill="var(--surface-ground)"
			animationDuration=".5s"
		/>
	</Dialog>

	<Dialog
		v-model:visible="confirmExnessLinkAccountDialog"
		:closeOnEscape="false"
		header=" "
		modal
	>
		<template #footer>
			<Button
				v-if="!memberStore.user?.exness"
				icon="pi pi-link"
				:loading="loading"
				:disabled="loading"
				:label="`ยืนยันการผูกบัญชี exness: ${tempExness.client_account}`"
				@click="confirmExnessLinkAccount($event)"
			/>
		</template>
	</Dialog>

	<Dialog
		v-model:visible="confirmXmLinkAccountDialog"
		:closeOnEscape="false"
		header=" "
		modal
	>
		<template #footer>
			<Button
				v-if="!memberStore.user?.xm"
				icon="pi pi-link"
				:loading="loading"
				:disabled="loading"
				:label="`ยืนยันการผูกบัญชี xm: ${tempXm.loginId}`"
				@click="confirmXmLinkAccount($event)"
			/>
		</template>
	</Dialog>

	<Dialog
		v-model:visible="confirmHfmLinkAccountDialog"
		:closeOnEscape="false"
		header=" "
		modal
	>
		<template #footer>
			<Button
				v-if="!memberStore.user?.hfm"
				icon="pi pi-link"
				:loading="loading"
				:disabled="loading"
				:label="`ยืนยันการผูกบัญชี hfm: ${tempHfm.client_id || tempHfm.id}`"
				@click="confirmHfmLinkAccount($event)"
			/>
		</template>
	</Dialog>

	<Dialog
		v-model:visible="confirmFxgtLinkAccountDialog"
		:closeOnEscape="false"
		header=" "
		modal
	>
		<template #footer>
			<Button
				v-if="!memberStore.user?.fxgt"
				icon="pi pi-link"
				:loading="loading"
				:disabled="loading"
				:label="`ยืนยันการผูกบัญชี fxgt: ${tempFxgt.login_id}`"
				@click="confirmFxgtLinkAccount($event)"
			/>
		</template>
	</Dialog>

	<Dialog
		v-model:visible="confirmAxiLinkAccountDialog"
		:closeOnEscape="false"
		header=" "
		modal
	>
		<template #footer>
			<Button
				v-if="!memberStore.user?.axi"
				icon="pi pi-link"
				:loading="loading"
				:disabled="loading"
				:label="`ยืนยันการผูกบัญชี axi: ${tempAxi.login}`"
				@click="confirmAxiLinkAccount($event)"
			/>
		</template>
	</Dialog>

	<Card>
		<template #title>
			<span v-html="configs?.frontendDashboardHeader?.contents || ''" />
    </template>
		<template #content>
			<div>
				<div
					v-if="!memberStore.user?.exness && !memberStore.user?.xm"
					v-html="configs?.frontendDashboardNoConnected?.contents || ''"
				/>

				<template v-if="!memberStore.user?.exness">
					<div id="frontendDashboardExnessNotConnected" />
				</template>
				<template v-else>
					<span v-html="exnessConnectedHTML" />
				</template>

				<template v-if="!memberStore.user?.xm">
					<div id="frontendDashboardXmNotConnected" />
				</template>
				<template v-else>
					<span v-html="xmConnectedHTML" />
				</template>

				<template v-if="!memberStore.user?.hfm">
					<div id="frontendDashboardHfmNotConnected" />
				</template>
				<template v-else>
					<span v-html="hfmConnectedHTML" />
				</template>

				<template v-if="!memberStore.user?.fxgt">
					<div id="frontendDashboardFxgtNotConnected" />
				</template>
				<template v-else>
					<span v-html="fxgtConnectedHTML" />
				</template>

				<template v-if="!memberStore.user?.axi">
					<div id="frontendDashboardAxiNotConnected" />
				</template>
				<template v-else>
					<span v-html="axiConnectedHTML" />
				</template>
			</div>

			<div id="frontendDashboardFooter" />
		</template>
	</Card>

	<!-- <Card>
		<template #title>
			ยินดีต้อนรับสู่พื้นที่สมาชิก Uhas
    </template>
		<template #content>
			<div>
				<p v-if="!memberStore.user?.exness && !memberStore.user?.xm">
					ในขณะนี้คุณเป็นสมาชิกทั่วไป สามารถอัปเดตเป็นสมาชิก Exclusive ได้แล้ววันนี้ เพื่อรับสิทธิพิเศษต่างๆ มากมายจาก Uhas
				</p>
				<p v-else>
					ในขณะนี้คุณเป็นสมาชิกครอบครัว Uhas เรียบร้อยแล้ว เราขอบพระคุณทุกความไว้วางใจ<br>
					หากคุณพบปัญญาใดๆ ในการเทรดสามารถติดต่อเราได้ตลอดเวลาที่ไลน์
					<a href="https://lin.ee/1eoK6Hd" target="_blank">@uhasthailand</a>
				</p>

				<template v-if="!memberStore.user?.exness">
					<div class="mt-6 field w-max max-w-full">
						<div class="p-inputgroup">
							<InputText
								v-model="exnessClientIdValidation.exnessClientID.$model"
								placeholder="เลขบัญชีจริง exness"
								:class="{'p-error': exnessClientIdValidation.exnessClientID.$invalid && exnessClientIdSubmitted}"
							/>
							<Button
								:loading="loading"
								:disabled="loading"
								icon="pi pi-link"
								class="p-button-outlined"
								label="ผูกบัญชี exness"
								@click="requestLinkWithExness"
							/>
						</div>
						<small v-if="(exnessClientIdValidation.exnessClientID.$invalid && exnessClientIdSubmitted) || exnessClientIdValidation.exnessClientID.$pending.$response" class="p-error">
							{{exnessClientIdValidation.exnessClientID.required.$message.replace("Value", "เลขบัญชีจริง exness")}}
						</small>
					</div>
					<p>
						เพียงแค่เปิดบัญชี exness ผ่านลิงค์
						<a href="https://one.exness-track.com/a/pf36v6cu" target="_blank">https://one.exness-track.com/a/pf36v6cu</a>
						เพียงแค่นี้คุณก็สามารถเป็นสมาชิกแบบ Exclusive ได้แล้ว
					</p>
				</template>

				<template v-if="!memberStore.user?.xm">
					<div class="mt-6 field w-max max-w-full">
						<div class="p-inputgroup">
							<InputText
								v-model="xmClientIdValidation.login_id.$model"
								placeholder="เลขบัญชีจริง xm"
								:class="{'p-error': xmClientIdValidation.login_id.$invalid && xmClientIdSubmitted}"
							/>
							<Button
								:loading="loading"
								:disabled="loading"
								icon="pi pi-link"
								class="p-button-outlined"
								label="ผูกบัญชี xm"
								@click="requestLinkWithXm"
							/>
						</div>
						<small v-if="(xmClientIdValidation.login_id.$invalid && xmClientIdSubmitted) || xmClientIdValidation.login_id.$pending.$response" class="p-error">
							{{xmClientIdValidation.login_id.required.$message.replace("Value", "เลขบัญชีจริง xm")}}
						</small>
					</div>
					<p>
						เพียงแค่เปิดบัญชี XM ผ่านลิงค์
						<a href="https://clicks.pipaffiliates.com/c?c=503827&l=th&p=0" target="_blank">https://clicks.pipaffiliates.com/c?c=503827&l=th&p=0</a>
						เพียงแค่นี้คุณก็สามารถเป็นสมาชิกแบบ Exclusive ได้แล้ว ไม่ว่าจะมีบัญชี XM อยู่แล้ว หรือยังไม่มีบัญชี XM สามารถเปิดบัญชีเพิ่มผ่านลิงค์ได้เลย
					</p>
				</template>

				<template v-if="!memberStore.user?.exness">
					<h4 class="mt-8">สำหรับลูกค้า exness ผู้ที่มีบัญชีแล้ว</h4>
					<p>
						1. ติดต่อ exness ที่
						<a href="https://one.exness-track.com/a/pf36v6cu" target="_blank">https://one.exness-track.com/a/pf36v6cu</a>
						ถามซัพพอร์ตว่า "หมายเลขบัญชีของลูกค้า อยู่ภายใต้ IB เลขบัญชี 549968 หรือยัง"
						<ul class="list-disc">
							<li>หากทาง Support แจ้งว่า อยู่ภายใต้เราแล้ว สามารถแจ้งเลขบัญชีกับเราได้เลยนะคะ</li>
							<li>หากทาง Support แจ้งว่า ยังไม่ได้อยู่ ให้ทำตามขั้นตอนที่ 2 ค่า</li>
						</ul>
						1.1. สำหรับผู้มีบัญชี exness แล้ว สามารถเปิดบัญชี exness ใหม่ได้ทันที โดยใช้เบอร์เดิม เปลี่ยนเพียงแค่อีเมลใหม่ บัญชีของท่านจะเข้าระบบทันที
					</p>
					<p>
						2. สำหรับผู้ที่มีบัญชี exness แล้ว สามารถติดต่อ exness ผ่านหน้าแชทบนเว็บไซต์ หรืออีเมล
						<a href="mailto:support@exness.com" target="_blank">support@exness.com</a>
						ข้อความว่า "ต้องการอยู่ภายใต้พาร์ทเนอร์
						<a href="https://one.exness-track.com/a/pf36v6cu" target="_blank">https://one.exness-track.com/a/pf36v6cu</a>
						หรือพาร์ทเนอร์เลขบัญชี
						<b>549968</b> เพื่อเป็นสมาชิก Uhas" เมื่อดำเนินการเรียบร้อยแล้ว ท่านก็จะกลายเป็นผู้สนับสนุนเราในทันที
					</p>
					<p>
						ปล. กรณี exness ไม่อนุญาตให้ย้าย IB สามารถกดลิงค์
						<a href="https://one.exness-track.com/a/pf36v6cu" target="_blank">https://one.exness-track.com/a/pf36v6cu</a>
						เพื่อเปิดบัญชีใหม่ได้ทันที สามารถใช้เบอร์มือถือเดิมได้ แต่ต้องเปลี่ยน email
					</p>
					<p>
						หากคุณพบปัญญาใดๆ ในการเทรดสามารถติดต่อเราได้ตลอดเวลาที่ไลน์ @uhasthailand
					</p>
				</template>
			</div>

			<div class="my-4 max-w-full">
				<img
					src="@/assets/banner-001.jpg"
					class="max-w-full"
					alt="uhas activity"
				/>
			</div>
			<p v-if="!memberStore.user?.exness && !memberStore.user?.xm">
				สิทธิพิเศษของ Member.uhas.com ที่เปิดบัญชี exness กับเรามีอะไรบ้าง?
				<ul class="list-decimal">
					<li>เข้าร่วมกิจกรรมแข่งเทรดได้ทันที ครั้งนี้ และครั้งอื่นๆ</li>
					<li>สิทธิ์ลุ้นไปเที่ยวต่างประเทศกับ Uhas ฟรี</li>
					<li>สิทธิ์ในการใช้ EA ของ Uhas ฟรี</li>
					<li>สิทธิ์ในการรับของขวัญเนื่องในวันพิเศษต่างๆ</li>
					<li>สิทธิ์ในการมีผู้ดูแลบัญชีการเทรดแบบ exclusive</li>
					<li>สิทธิ์ในการลุ้นรางวัลจาก Uhas</li>
					<li>สิทธิ์ในการอบรม ทำความเข้าใจการเทรด</li>
					<li>สิทธิ์ในการ Meeting กับ Uhas และสมาชิกคนอื่นๆ ฟรี และอื่นๆ อีกมากมาย</li>
				</ul>
			</p>
			<p v-else>
				สำหรับสมาชิกพิเศษอย่างคุณ เรามีที่ปรึกษาส่วนตัวพิเศษ สามารถติดต่อเราได้ที่ <a href="https://lin.ee/1eoK6Hd" target="_blank">@uhasthailand</a>
				หรือไลน์กลุ่มประชาสัมพันธ์กิจกรรม <a href="https://line.me/ti/g2/MMqLbiaB8x3JSB7tQXVEuiZ1cdPMgpgEaZJffQ?utm_source=invitation&utm_medium=link_copy&utm_campaign=default" target="_blank">คลิก</a>
			</p>

			<DataTable
				v-if="configs?.uhasActivityActive?.leaderboard_show === true && liveActivity && liveActivity.leaderboards"
				class="p-datatable-sm mt-6"
				responsiveLayout="scroll"
				:value="liveActivity.leaderboards?.filter(item => item.score && item.score > 0).slice(0, leaderboardsRows)"
				rowHover
			>
				<template #header>
					<h2>Leaderboard</h2>
					<small class="text-primary">Updated At : {{ liveActivity.leaderboards_updated_at }}</small>
				</template>

				<Column header="Place">
					<template #body="{index}">
						<b>{{ index + 1 }}</b>
					</template>
				</Column>
				<Column header="User">
					<template #body="{data}">
						<Avatar
							:image="data.avatar"
							class="mt-1"
							shape="circle"
							size="large"
							@error="data.avatar = 'https://cdn.uhas.com/avatar/no-img.png'"
						/>
					</template>
				</Column>
				<Column field="uhas_uid" header="ID"></Column>
				<Column field="abs_gain" header="Abs. Gain">
					<template #body="{data}">
						<span :class="[data.abs_gain >= 0 ? 'text-green-500' : 'text-pink-500']">{{ data.abs_gain }}%</span>
					</template>
				</Column>
				<Column field="drawdown" header="DD"></Column>
				<Column field="score" header="Score">
					<template #body="{data}">
						<b>{{ data.score }}</b>
					</template>
				</Column>

				<template
					#footer
					v-if="leaderboardsRows !== 100"
				>
					<div class="text-center">
						<Button
							class="mx-auto p-button-text"
							@click="leaderboardsRows = 100"
						>
							<i class="pi pi-angle-double-down"></i>
							<span class="mx-3">Show Top 100</span>
							<i class="pi pi-angle-double-down"></i>
						</Button>
					</div>
				</template>
			</DataTable>

			<span v-html="configs?.frontendDashboardFooter.contents" />
		</template>
	</Card> -->
</template>
