<script setup>
import {onMounted, reactive, ref, watch} from "vue";
import {useToast} from "primevue/usetoast";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {storeToRefs} from "pinia";
import {MemberStore} from "@/uhas/store/member";

import provinces from "@/../../configs/location_th/provinces";

const toast = useToast();

const memberStore = MemberStore();
const {memberData, memberInform} = storeToRefs(memberStore);

const loading = ref(false);
const addressDialog = ref(false);

const basicInfoState = reactive({...memberStore.user});
const basicInfoValidation = useVuelidate({
	province_id: {required}
}, basicInfoState);
const basicInfoSubmitted = ref(false);


/**
 * Watch
 */
watch(memberData, val => {
	basicInfoState.province_id = val.province_id
}, { deep: true });
watch(memberInform, val => {
	addressDialog.value = !!val.addressSpecify
}, { deep: true });


/**
 * Mounted
 */
onMounted(() => {
	addressDialog.value = !!memberInform.value.addressSpecify
});
/**
 * Actions
 */
const saveBasicInfo = async () => {
	basicInfoSubmitted.value = true;
	if (basicInfoValidation.value.$invalid) {
		return
	}

	loading.value = true;
	memberStore.saveBasicInfo({
		user: basicInfoState
	}).then(result => {
		memberStore.user = {
			...memberStore.user,
			...result.user
		};

		toast.add({
			severity:"success",
			summary: "Success Message",
			detail: "Profile updated.",
			life: 10000
		});

		addressDialog.value = false
	}).finally(() => {
		loading.value = false
	})
}
</script>

<style lang="scss" scoped>

</style>

<template>
	<Toast />
	<Dialog
		v-model:visible="addressDialog"
		:closeOnEscape="false"
		:style="{ width: '25rem' }"
		:closable="false"
		header=""
		modal
	>
		<template #header>
			<h4>ระบุจังหวัดของคุณ</h4>
		</template>

		<div class="field">
			<div class="p-float-label">
				<Dropdown
					v-model="basicInfoValidation.province_id.$model"
					id="province_id"
					optionLabel="name"
					optionValue="id"
					placeholder="Province"
					class="w-full"
					:class="{'p-invalid':basicInfoValidation.province_id.$invalid && basicInfoSubmitted}"
					:options="provinces"
					filter
				/>
				<label
					for="province_id"
					:class="{'p-error':basicInfoValidation.province_id.$invalid && basicInfoSubmitted}"
				>
					Province
				</label>
			</div>
		</div>

		<template #footer>
			<Button
				label="ไว้คราวหลัง"
				class="p-button-text"
				severity="secondary"
				@click="addressDialog = false"
				autofocus
			/>
			<Button
				icon="pi pi-save"
				:loading="loading"
				:disabled="loading"
				label="บันทึก"
				@click="saveBasicInfo"
			/>
		</template>
	</Dialog>
</template>
